.lessons {
	background-color: #ffffff;

	.addGroupCourse {
		.result {
			padding: 10px;
			text-align: center;
			color: #6000d3;
		}

		.resultError {
			padding: 10px;
			text-align: center;
			color: red;
			width: 70%;
		}

		.step {
			background-color: #f6f7f8;
			margin: 35px;
			padding: 20px;
			width: 80%;

			h3 {
				color: $primary;
			}

			.detailsFrom {
				width: 50%;

				.multiOption {
					font-size: 14px;
					padding-left: 20px;
					border-bottom: 1px dotted #acbbd7;

					&:checked {
						padding-left: 2px;

						&:before {
							content: "✔  ";
						}
					}
				}
			}

			.dateTimeError {
				padding: 10px;
				color: $danger;
			}

			.selectedDays {
				padding-bottom: 20px;

				.dayList {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;

					.singleDate {
						padding-right: 10px;
					}
				}
			}

			#sameTime {
				margin-right: 10px;
			}

			.timeRow {
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;
				padding-bottom: 10px;
			}
		}
	}

	.groupCoursesList {
		list-style-type: none;
		padding-inline-start: 10px;
		margin-inline-end: 10px;
	}

	.groupCoursePage {
		padding: 10px;
	}

	.singleGroupCourse {
		border: 1px solid #bebebe;
		margin: auto;
		margin-bottom: 25px;
		border-radius: 5px;

		@media (min-width: 1600px) {
			max-width: 80%;
		}

		.header {
			padding: 8px;
			border-radius: 5px;
			background: #f6f7f8;
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.formType {
				padding-left: 20px;
				font-size: 0.9em;
			}

			.deleteLink {
				color: red;

				&:hover {
					font-weight: bold;
					cursor: pointer;
				}
			}
		}

		.actions {
			background: #ffffff;
			padding: 8px;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			border-top: 1px solid #bebebe;

			button {
				margin-right: 25px;
				min-width: 150px;
			}

			span {
				margin-right: 25px;
			}
		}

		.togglableContent {
			padding: 8px;
			clear: both;
			width: 95%;
			overflow: auto;

			.fullSchedule+.reservationsList {
				margin-top: 1rem;
			}

			.reservationsList {
				.reservation {
					display: grid;
					grid-template-columns: 1fr 2fr 2fr 1fr 2fr 3fr 2fr 1fr 1fr;
					column-gap: 8px;
					cursor: pointer;

					span,
					input {
						display: block;
						padding: 4px;
						align-self: baseline;
						justify-self: start;
					}

					div {
						cursor: default;
						display: flex;
						flex-direction: column;
						justify-content: center;
					}

					&:hover {
						background: #d1d0e3;
					}
				}

				.header {
					font-weight: bolder;
					cursor: default;
				}
			}
		}

		.details {
			padding: 8px;
			display: flex;
			flex-direction: row;
			justify-content: flex-start;

			h3 {
				color: #6000d3;
			}

			>div {
				padding: 15px;
				flex-grow: 1;

				.trainer {
					font-size: 1.1em;
				}

				.detailsRow {
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					.value {
						font-weight: bold;
						text-transform: capitalize;
						display: flex;
						flex-direction: row;

						.multiParam {
							font-size: 0.9em;
							margin-left: 15px;
						}
					}

					.colored {
						color: #6000d3;
					}
				}

				.descRow {
					display: flex;
					flex-direction: column;

					.value {
						font-style: italic;
						max-width: 300px;
					}
				}
			}
		}
	}
}

.deleteCourseModal {
	max-width: 650px;

	.warning {
		color: red;
	}

	.reservationsOptions {
		padding: 5px;

		label {
			display: block;

			input {
				margin-right: 8px;
			}
		}
	}

	.errorMessage {
		color: red;
		padding: 15px;
	}
}

.reservation-details {
	margin-top: 1rem;

	p {
		font-weight: bold;
		font-size: 1.125rem;
	}

	&--participant,
	&--guardian {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-auto-flow: column;
		align-items: end;

		label {
			display: flex;
			flex-direction: column;

			input,
			select {
				margin-bottom: 1rem;
				width: 80%;
			}

			input[type="checkbox"] {
				width: auto;
			}
		}
	}

	&--participant {
		grid-template-rows: repeat(4, 1fr);
	}

	&--guardian {
		grid-template-rows: repeat(2, 1fr);
	}
}

.searchContainer {
	padding-left: 10px;

	.searchInput {
		width: 200px;
	}

	.card {
		margin: 0;
		max-width: 800px;
	}
}
