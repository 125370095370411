.max-w-sm {
	max-width: 576px;
}

.max-w-md {
	max-width: 768px;
}

.max-w-lg {
	max-width: 992px;
}

.max-w-xl {
	max-width: 1200px;
}

.w-full {
	width: 100%;
}

.flex-1 {
	flex: 1;
}

.text-pre-wrap {
	white-space: pre-wrap;
}

.btn-icon {
	padding: 0.5rem;
	line-height: 0;
}

.td-last {
	width: 1px;
	white-space: nowrap;
}
