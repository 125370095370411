.snackbar {
	position: fixed;
	bottom: 0;
	z-index: 1100;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	padding: 0 1rem;
	max-width: 576px;
}

.snackbar .alert {
	border-radius: 25px;
}
