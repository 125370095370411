$header_height: 60px;
$sidebar_width: 280px;

$primary: #6000d3;
$secondary: #dbe3e8;
$success: #0ead69;
$danger: #c83e4d;
$warning: #ffd23f;
$info: #00ccde;

$font-family-sans-serif: "Nunito", Helvetica, sans-serif;
