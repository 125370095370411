$enable-negative-margins: true;

@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~react-big-calendar/lib/sass/styles";

@import "animations";
@import "admin-users";
@import "customers";
@import "lessons";
@import "snackbar";
@import "calendar";
@import "utils";

html,
body {
	background-color: white;
	height: 100%;
	width: 100%;
}

h1,
h2 {
	color: $primary;
	margin-bottom: 30px;
	// margin-left: 20px;
}

table {
	th,
	td {
		vertical-align: middle;
	}

	tbody {
		font-size: 0.875rem;

		tr:last-child td {
			border-bottom-width: 0;
		}
	}
}

#app {
	width: 100vw;
	position: relative;
}

// card formatting
.card:not(.card--plain) {
	border-radius: 0;
	border: none;
	margin: auto;
	max-width: 400px;

	.card-header {
		background: none;
		border: none;
	}

	.card-body {
		display: flex;
		flex-direction: column;
	}

	.card-subtitle {
		margin-top: 0;
	}

	p {
		margin-bottom: 0;
	}

	@media only screen and (max-width: 400px) {
		border-left: 0;
		border-right: 0;
	}
}

// list formatting
.list-group {
	.item-wrapper {
		width: 60% !important;
		align-self: center !important;

		@media (min-width: 1024px) {
			width: auto !important;
			align-self: auto !important;
		}
	}

	.list-group-item {
		position: relative;

		.arrow-right {
			margin-left: 10px;
			width: 19px;
			height: 18px;
			display: inline;
			position: absolute;
			right: 30px;
			top: 50%;
		}

		.item-card {
			background: none;
			margin: 0;
			padding-right: 2rem;

			.card-body {
				padding: 1.25rem 0;
			}
		}
	}
}

.main-header {
	position: sticky;
	top: 0;
	z-index: 5;
	height: $header_height;
	background-color: white;
	box-shadow: 0 2px 34px rgba(0, 0, 0, 0.08);

	.log-out-btn {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.header-bg {
		$color: rgb(2, 204, 222);

		background: $color;
		background: linear-gradient(30deg, $color 0%, $color 73%, rgba(255, 255, 255, 1) 74%);
		height: 100%;
		max-width: 400px;
		position: relative;
		padding: 13px 0;
		padding-left: 20px;
	}

	.logo {
		width: 100px;
		fill: white;
	}
}

.container {
	max-width: 500px;
	padding: 30px 0px;
}

.content-wrapper {
	margin: 20px;
}

.sidebar {
	position: fixed;
	top: $header_height;
	bottom: 0;
	left: 0;
	background-color: #f6f7f8;
	width: $sidebar_width;
	padding: 3.25rem 1rem;

	ul {
		list-style-type: none;
		padding-inline-start: 2rem;

		li {
			font-size: 1.125rem;

			.selected {
				color: #6000d3;
			}

			a {
				color: #000000;
			}
		}
	}
}

main {
	padding: 3rem 1.25rem;

	.loading {
		padding: 4rem 0;
	}
}

.loading {
	display: flex;
	padding: 2rem 0;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: $primary;

	.spinner-grow {
		margin-right: 0.5rem;
	}
}

.main--with-sidebar {
	margin-left: $sidebar_width;
}

.avatar {
	display: inline-block;
	$size: 6rem;
	width: $size;
	height: $size;
	flex-shrink: 0;
}

.avatar--empty {
	border-radius: 100%;
	background-color: #f6f7f8;
}

.purple {
	color: $primary;
}

.panel {
	background-color: #f6f7f8;
	padding: 1.25rem;
	border-radius: 0.25rem;

	h5 {
		margin-bottom: 0.75rem;
		color: $primary;
	}
}

.btn {
	border-radius: 25px;
}

.btn:not(.btn-sm):not(.btn-icon) {
	padding: 0.375rem 1.25rem;
}

.btn-link {
	text-decoration: inherit;
	color: #000;
}

.btn-info {
	&,
	&:hover {
		color: #fff;
	}
}

a {
	text-decoration: inherit;
}

.dropdown-toggle::after {
	display: none !important;
}

.dropdown-item {
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.dropdown-item--danger {
	&,
	&:hover {
		color: $danger;
	}

	&:active {
		color: $white;
	}
}

.dropdown-item--danger:active {
	background-color: $danger;
}

.leading-0 {
	line-height: 0;
}

.alert p {
	margin-bottom: 0;
}

.section-loader {
	z-index: 10;
	background-color: rgba($color: #fff, $alpha: 0.75);
}

.btn-close {
	flex-shrink: 0;
}

.modal-background {
	background-color: rgba(0, 0, 0, 0.5);
}

.vc-card {
	border: 1px solid purple;
	padding: 10px;
	border-radius: 15px;
	width: 45%;

	@media (min-width: 1280px) {
		width: 32%;
	}
}

.small-loader {
	display: flex;
	padding: 0;
	justify-content: center;
	align-items: center;
	color: $primary;
}

.vc-left {
	display: flex;
	align-items: center;
	color: #999999;
}
