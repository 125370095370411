.customers-details {
	padding: 1rem;

	h1 span {
		color: #919191;
		font-size: 1rem;
	}

	.header {
		margin-top: 2rem;
		margin-bottom: 0.75rem;

		.arrow-right {
			transform: rotateY(180deg) scale(0.8);
		}
	}
}

.user-card {
	display: flex;

	@media (min-width: 1024px) {
		flex-direction: row;
	}

	padding: 0 !important;
	margin: 0 !important;
	max-width: 100% !important;

	.spinner-grow {
		margin: 0 auto;
	}
}

.customer-info-card {
	display: flex;

	* {
		font-size: 1.25rem;
	}

	&__title {
		text-align: center;

		@media (min-width: 1024px) {
			text-align: left;
			padding-left: 1rem;
		}
	}

	@media (min-width: 1024px) {
		width: 60%;

		* {
			text-align: left;
		}
	}
}
