@import "variables";

.admin-users {
	.admin-loader {
		text-align: center;
	}
	.list-group-item {
		position: relative;

		@media only screen and (max-width: 500px) {
			border-radius: 0;
			border-left: 0;
			border-right: 0;
		}
	}
}
