.rbc-events-container {
	margin-right: 0 !important;
}

.rbc-today {
	background-color: rgba($color: $info, $alpha: 0.25);
}

.rbc-off-range-bg {
	background-color: rgba($color: $secondary, $alpha: 0.25);
}

.rbc-current-time-indicator {
	background-color: $danger;
	height: 2px;

	&::before {
		content: "";
		position: absolute;
		right: 100%;
		top: 1px;
		transform: translate(50%, -50%);
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $danger;
	}
}

.rbc-event,
.rbc-background-event {
	// border: none !important;
	// border-radius: 0 !important;

	animation: fade-in 0.15s ease-out forwards;
}

.rbc-event {
	background-color: $primary;
}

.rbc-day-slot .rbc-background-event {
	background-color: $success  !important;
}